<script setup>
const items = [
  {
    is_completed: true,
    title: "Cabinet of Curiosities",
    body: "It all began with the release of our mint pass collection, the Cabinet of Curiosities!",
    links: [
      // {
      //   label: "Buy on OpenSea",
      //   link: "https://opensea.io/collection/soth-cabinet-of-curiosities",
      //   target: "_blank",
      // },
    ],
  },
  {
    is_completed: true,
    title: "Genesis Collection",
    body: "Our collection of 8,888 characters—Citizens, Competitors, Stewards, and Nigel—is available now!",
    links: [
      {
        label: "See the characters",
        link: "/gallery",
        // target: "_blank",
      },
      // {
      //   label: "Buy a character",
      //   link: "/buy",
      //   // target: "_blank",
      // },
      // {
      //   label: "Buy on OpenSea",
      //   link: "https://opensea.io/collection/society-of-the-hourglass",
      //   target: "_blank",
      // },
      // {
      //   label: "Buy on Nifty Gateway",
      //   link: "https://niftygateway.com/marketplace/collectible/0x116fda1bf1f9c38a98afe2a8fd67ba5b9714dfa9",
      //   target: "_blank",
      // },
    ],
  },
  {
    is_completed: true,
    title: "Games",
    body: "Experience being a part of The Society and earn points to be redeemed for characters, merch, prizes, and other exclusive rewards!",
    links: [
      {
        label: "Daily Trivia",
        link: "/arcade/trivia",
      },
      {
        label: "Powerup Poker",
        link: "/arcade/poker",
      },
      {
        label: "Memory Match",
        link: "/arcade/memory",
      },
      {
        label: "Portal Redemptions",
        link: "/arcade/portal",
      },
    ],
    image: require("@/assets/v2/home/cards-min.png"),
  },
  {
    is_completed: false,
    title: "Seek-and-find Book",
    body: "Join Nigel and the Competitors through a seek-and-find story book!",
    // links: [
    //   {
    //     label: "Read More...",
    //     link: "/book",
    //   },
    // ],
    image: require("@/assets/v2/home/book-min.png"),
    imageTop: "200px",
  },
  {
    is_completed: false,
    title: "Animated Series",
    body: "Join the Writer’s Room and be a part of creating an exciting new entertainment IP. We’re all gonna make a series! #WAGMAS",
    // links: [
    //   {
    //     label: "Read More...",
    //     link: "/animated-series",
    //   },
    // ],
  },
  // {
  //   is_completed: false,
  //   title: "Holder Rewards",
  //   body: "Our partner platform, Hashku, makes launching projects easier than ever, and Society holders reap the rewards!",
  //   links: [
  //     {
  //       label: "Learn About Hashku...",
  //       link: "https://www.hashku.com/",
  //       target: "_blank",
  //     },
  //   ],
  // },
  {
    is_completed: null,
    title: "Other Surprises",
    body: "We’re charting a course into the future of entertainment IP—constantly exploring new ideas, opportunities, and adventures!",
  },
];

const isEven = (num) => {
  if (num % 2 == 0) {
    return true;
  }

  return false;
};
</script>

<template>
  <div class="bg-white">
    <div class="container flex flex-col items-center overflow-hidden lg:overflow-visible">
      <div class="mt-16 md:mt-24 mb-8 text-blue">
        <h1 class="text-4xl font-brother font-bold text-center">
          We're working on some big things!
        </h1>
      </div>
      <div class="flex justify-center mb-24">
        <div class="flex mr-12 items-center">
          <div><img :src="require('@/assets/v2/home/dot-min.png')" style="width: 40px" /></div>
          <div class="ml-2 text-blue text-lg">In Progress...</div>
        </div>
        <div class="flex items-center">
          <div><img :src="require('@/assets/v2/home/dot-done-min.png')" style="width: 40px" /></div>
          <div class="ml-2 text-green text-lg">Done!</div>
        </div>
      </div>
      <div class="mx-auto">
        <div class="roadmap-item" v-for="(item, i) in items" :key="i">
          <div>
            <div class="flex justify-center">
              <div class="left text-right w-1/2 md:w-1/3 -mt-4 hidden md:block pb-8">
                <div :class="{ even: isEven(i), odd: !isEven(i) }" class="relative">
                  <div
                    class="text-2xl font-brother font-bold"
                    :class="{
                      'text-green': item.is_completed === true,
                      'text-blue': item.is_completed === false,
                      'text-ch1blue': item.is_completed === null,
                    }"
                  >
                    {{ item.title }}
                  </div>
                  <div>{{ item.body }}</div>
                  <div v-if="item?.links?.length" class="font-medium text-blue underline mt-6">
                    <div v-for="(link, l) in item.links" :key="l" class="mb-4 md:mb-2">
                      <a :href="link.link" class="cursor-pointer" :target="link.target">{{
                        link.label
                      }}</a>
                    </div>
                  </div>
                  <div
                    v-if="item.image"
                    class="absolute bottom-0 left-0"
                    style="width: 250px; transform: translate(-100px, 150px)"
                  >
                    <img :src="item.image" />
                  </div>
                </div>
              </div>
              <div class="left-line pl-8 hidden md:block" style="width: 100px">
                <div :class="{ even: isEven(i), odd: !isEven(i) }">
                  <img
                    :src="require('@/assets/v2/home/line-done-min.png')"
                    v-if="item.is_completed === true"
                  />
                  <img
                    :src="require('@/assets/v2/home/line-min.png')"
                    v-if="item.is_completed === false"
                  />
                  <img
                    :src="require('@/assets/v2/home/line-tbd-min.png')"
                    v-if="item.is_completed === null"
                  />
                </div>
              </div>
              <div class="center w-0 relative overflow-visible">
                <!-- <div class="absolute top-0 left-0 z-50"></div> -->
                <img
                  :src="require('@/assets/v2/home/dot-done2-min.png')"
                  style="
                    width: 40px;
                    max-width: 40px;
                    left: -19px;
                    top: -19px;
                    position: absolute;
                    z-index: 60;
                  "
                  v-if="item.is_completed === true"
                />
                <img
                  :src="require('@/assets/v2/home/dot2-min.png')"
                  style="
                    width: 40px;
                    max-width: 40px;
                    left: -19px;
                    top: -19px;
                    position: absolute;
                    z-index: 60;
                  "
                  v-if="item.is_completed === false"
                />
                <img
                  :src="require('@/assets/v2/home/dot-pending2-min.png')"
                  style="
                    width: 30px;
                    max-width: 30px;
                    left: -15px;
                    top: -15px;
                    position: absolute;
                    z-index: 60;
                  "
                  v-if="item.is_completed === null"
                />
                <div
                  class="border-l-4 w-0 h-full absolute top-0 left-0 z-10"
                  :class="{
                    'border-green': item.is_completed === true,
                    'border-blue': item.is_completed === false,
                    'border-none': item.is_completed === null,
                  }"
                ></div>
              </div>
              <div class="right-line pr-8" style="width: 100px">
                <div :class="{ even: isEven(i), odd: !isEven(i) }">
                  <img
                    :src="require('@/assets/v2/home/line-done-min.png')"
                    v-if="item.is_completed === true"
                  />
                  <img
                    :src="require('@/assets/v2/home/line-min.png')"
                    v-if="item.is_completed === false"
                  />
                  <img
                    :src="require('@/assets/v2/home/line-tbd-min.png')"
                    v-if="item.is_completed === null"
                  />
                </div>
              </div>
              <div class="right w-1/2 md:w-1/3 -mt-4 pb-8">
                <div :class="{ even: isEven(i), odd: !isEven(i) }" class="relative">
                  <div
                    class="text-2xl font-brother font-bold"
                    :class="{
                      'text-green': item.is_completed === true,
                      'text-blue': item.is_completed === false,
                      'text-ch1blue': item.is_completed === null,
                    }"
                  >
                    {{ item.title }}
                  </div>
                  <div>{{ item.body }}</div>
                  <div v-if="item?.links?.length" class="font-medium text-blue underline mt-6">
                    <div v-for="(link, l) in item.links" :key="l" class="mb-4 md:mb-2">
                      <a :href="link.link" class="cursor-pointer" :target="link.target">{{
                        link.label
                      }}</a>
                    </div>
                  </div>
                  <div
                    v-if="item.image"
                    class="hidden md:block absolute bottom-0 right-0"
                    :style="{
                      width: '250px',
                      transform: `translate(100px, ${item?.imageTop ?? '150px'})`,
                    }"
                  >
                    <img :src="item.image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full px-0 md:px-24 flex items-end justify-center md:justify-start h-auto md:h-40 overflow-visible md:pr-32"
      >
        <img :src="require('@/assets/v2/home/leonardo-da-vinci-min.png')" class="h-60" />
      </div>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.left .odd {
  @apply block md:hidden;
}
.right .even {
  @apply block md:hidden;
}
.left-line, .right-line {
  width:
}
.left-line .odd {
  @apply block md:hidden;
}
.right-line .even {
  @apply block md:hidden;
}
</style>
